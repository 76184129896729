import logger from '@/lib/logger';
import logrocket from '@/lib/logrocket';
import httpService from '@/lib/httpService';
import notificationService from '@/lib/notificationService';
import twoFactorAuthenticationConsts from '@/consts/twoFactorAuthenticationConsts';
import langConsts from '@/lang/en';
import apiConsts from '@/api/apiConsts';

export default {
    async initializeLoginProcess({ oidcReturnUrl } = {}) {
        const apiRequestParams = {
            oidcReturnUrl
        };

        try {
            const initializeLoginResponse = await httpService.postAsync(apiConsts.initializeLoginProcess, apiRequestParams);
            return initializeLoginResponse.data;
        } catch (ex) {
            logger.error(langConsts.initializeLoginError);
            logrocket.error(ex);
            notificationService.error(langConsts.initializeLoginError);
        }
    },
    async initializeLoginByUserFlow({ userEmail, oidcReturnUrl } = {}) {
        const apiRequestParams = {
            userEmail,
            oidcReturnUrl
        };

        try {
            const initializeLoginByUserFlowResponse = await httpService.postAsync(apiConsts.initializeLoginByUserFlow, apiRequestParams);
            if (!initializeLoginByUserFlowResponse.data) {
                return { isValid: false };
            }
            initializeLoginByUserFlowResponse.data.isValid = true;
            return initializeLoginByUserFlowResponse.data;
        } catch (ex) {
            logger.error(langConsts.initializeLoginByUserFlowError);
            logrocket.error(ex);
            return { isValid: false };
        }
    },
    async authenticateUserPassword({ userEmail, password, oidcReturnUrl } = {}) {
        const apiRequestParams = {
            userEmail,
            password,
            oidcReturnUrl
        };

        try {
            const authenticateUserPasswordResponse = await httpService.postAsync(apiConsts.authenticateUserPassword, apiRequestParams);
            if (!authenticateUserPasswordResponse.data) {
                return { showError: true, errorMessage: langConsts.validateUserError };
            }

            authenticateUserPasswordResponse.data.showError = false;
            return authenticateUserPasswordResponse.data;
        } catch (ex) {
            logger.error(langConsts.validateUserError);
            logrocket.error(ex);
            return { showError: true, errorMessage: langConsts.validateUserError };
        }
    },
    async canSubscribe() {
        const url = apiConsts.canSubscribe;
        try {
            const response = await httpService.getAsync(url);
            if (!response.data) {
                return { showError: true, errorMessage: langConsts.validateUserError };
            }

            response.data.showError = false;
            return response.data;
        } catch (ex) {
            logger.error(langConsts.validateUserError);
            logrocket.error(ex);
            return { showError: true, errorMessage: langConsts.validateUserError };
        }
    },
    async getPayersForCurrentLoginStateUser() {
        const url = apiConsts.getPayersForCurrentLoginStateUser;

        try {
            const response = await httpService.getAsync(url);

            if (!response.data) {
                return { showError: true, errorMessage: langConsts.getPayersForCurrentLoginStateUserError };
            }

            response.data.showError = false;
            return response.data;
        } catch (ex) {
            logger.error(langConsts.getPayersForCurrentLoginStateUserError);
            logrocket.error(ex);
            return { showError: true, errorMessage: langConsts.getPayersForCurrentLoginStateUserError };
        }
    },
    async createAndSigninApplicativeUser({ selectedPayerId, selectedPayerEntityIdArray } = {}) {
        const url = apiConsts.createAndSigninApplicativeUser;
        const apiRequestParams = {
            selectedPayerId,
            selectedPayerEntityIdArray
        };

        try {
            const response = await httpService.postAsync(url, apiRequestParams);

            if (!response.data) {
                return { showError: true, errorMessage: langConsts.createAndSigninApplicativeUser };
            }

            response.data.showError = false;
            return response.data;
        } catch (ex) {
            logrocket.error(ex);
            logger.error(langConsts.createAndSigninApplicativeUser);
            return { showError: true, errorMessage: langConsts.createAndSigninApplicativeUser };
        }
    },
    async getScopesByLoginState() {
        const url = apiConsts.getScopesByLoginState;
        try {
            const response = await httpService.getAsync(url);

            if (!response.data) {
                return { showError: true, errorMessage: langConsts.getClientScopesByLoginStateError };
            }

            response.data.showError = false;
            return response.data;
        } catch (ex) {
            logger.error(langConsts.getClientScopesByLoginStateError);
            logrocket.error(ex);
            return { showError: true, errorMessage: langConsts.getClientScopesByLoginStateError };
        }
    },
    async grantConsent({ oidcReturnUrl, consentedScopes } = {}) {
        const url = apiConsts.grantConsent;
        const apiRequestParams = {
            oidcReturnUrl,
            consentedScopes
        };

        try {
            const response = await httpService.postAsync(url, apiRequestParams);

            if (!response.data) {
                return { showError: true, errorMessage: langConsts.grantConsentError };
            }

            return { redirectUrl: response.data, showError: false };
        } catch (ex) {
            logger.error(langConsts.grantConsentError);
            logrocket.error(ex);
            return { showError: true, errorMessage: langConsts.grantConsentError };
        }
    },
    async getAuthenticatorSetupInfoAsync() {
        const url = twoFactorAuthenticationConsts.apiUrls.getSetupInfo;
        try {
            const response = await httpService.getAsync(url);
            return response.data;
        } catch (ex) {
            notificationService.error(twoFactorAuthenticationConsts.getAuthenticatorSetupInfoError);
            return {
                isExistingUser: false,
                qrCodeImage: '',
                manualEntryKey: '',
                isError: true
            };
        }
    },
    async validatePinCodeAsync({ code }) {
        const url = twoFactorAuthenticationConsts.apiUrls.validateTwoFactorCode;
        const params = { code };
        try {
            const response = await httpService.postAsync(url, params);
            return response.data;
        } catch (ex) {
            notificationService.error(twoFactorAuthenticationConsts.validatePinCodeError);
            return { validationStatus: twoFactorAuthenticationConsts.pinCodeValidationStatus.generalError };
        }
    }
};

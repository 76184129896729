import Vue from 'vue';

function error(text) {
    Vue.notify({
        position: 'top center',
        type: 'error',
        text
    });
}
function success(text) {
    Vue.notify({
        position: 'top center',
        type: 'success',
        text
    });
}

function clearAll() {
    Vue.notify({
        clean: true
    });
}

export default {
    clearAll,
    error,
    success
    // info
    // warning
};

import Vue from 'vue';
import Vuex from 'vuex';
import login from '@/store/modules/login';
import consent from '@/store/modules/consent';
import twoFactor from '@/store/modules/twoFactor';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        login,
        consent,
        twoFactor
    }
});

import api from '@/api';
import Vue from 'vue';
import consts from '@/consts/twoFactorAuthenticationConsts';

export const SET_LOADING = 'SET_LOADING';
export const SET_SETUP_INFO = 'SET_SETUP_INFO';
export const SET_PIN_CODE = 'SET_PIN_CODE';
export const SET_CAN_VERIFY = 'SET_CAN_VERIFY';
export const SET_VALIDATION_RESULT = 'SET_VALIDATION_RESULT';
export const SET_VERIFYING = 'SET_VERIFYING';

const validationResultValid = { validationStatus: consts.pinCodeValidationStatus.success };
export const getDefaultState = () => {
    return {
        email: 'local@tipalti.com',
        isLoading: false,
        isVerifying: false,
        setupInfo: {
            isExistingUser: true,
            isError: false
        },
        pinCode: null,
        canVerify: false,
        validationResult: validationResultValid
    };
};
const state = getDefaultState();
const getters = {
    isFirstLogin: (state) => {
        if (!state.setupInfo) {
            return true;
        }
        return !state.setupInfo.isExistingUser;
    },
    isPinCodeValid: (state) => {
        return state.validationResult?.validationStatus === consts.pinCodeValidationStatus.success;
    },
    didReceiveErrorInSetup: (state) => {
        return state.setupInfo.isError;
    }
};

const actions = {
    async loadSetupModel({ commit }) {
        commit(SET_LOADING, true);
        const setupInfo = await api.getAuthenticatorSetupInfoAsync();
        if (setupInfo) {
            commit(SET_SETUP_INFO, setupInfo);
        }
        commit(SET_LOADING, false);
    },
    updatePinCode({ commit }, pinCode) {
        commit(SET_PIN_CODE, pinCode);
        commit(SET_VALIDATION_RESULT, validationResultValid);
        if (pinCode.length < consts.minPinCodeLength) {
            commit(SET_CAN_VERIFY, false);
            return;
        }
        commit(SET_CAN_VERIFY, true);
    },
    async validatePinCode({ commit, state }) {
        commit(SET_VERIFYING, true);
        const params = { code: state.pinCode, email: state.email };
        const validationResult = await api.validatePinCodeAsync(params);
        if (validationResult) {
            commit(SET_VALIDATION_RESULT, validationResult);
        }
        commit(SET_VERIFYING, false);
    }
};

const mutations = {
    [SET_SETUP_INFO](state, data) {
        Vue.set(state, 'setupInfo', data);
    },
    [SET_LOADING](state, isLoading) {
        Vue.set(state, 'isLoading', isLoading);
    },
    [SET_VERIFYING](state, isVerifying) {
        Vue.set(state, 'isVerifying', isVerifying);
    },
    [SET_PIN_CODE](state, pinCode) {
        Vue.set(state, 'pinCode', pinCode);
    },
    [SET_CAN_VERIFY](state, canVerify) {
        Vue.set(state, 'canVerify', canVerify);
    },
    [SET_VALIDATION_RESULT](state, validationResult) {
        Vue.set(state, 'validationResult', validationResult);
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default {
    initializeLoginError: 'Failed to initiate login process, please try again',
    initializeLoginByUserFlowError: 'Something went wrong. Please try again later.',
    validateUserError: 'Something went wrong. Please try again later.',
    unexpectedError: 'Unexpected error, please try again or contact support',
    getPayersForCurrentLoginStateUserError: 'Can’t load payers list. Please try again later.',
    getClientScopesByLoginStateError: 'Something went wrong. Please try again later.',
    createAndSigninApplicativeUser: 'We couldn’t complete this action. Please try again later.',
    canSubscribeError: 'Failed to check whether user can subscribe application',
    permissionMessage: 'Ask your Admin to connect Tipalti to',
    grantConsentError: 'Something went wrong. Please try again later.',
    titleLogin: 'Login',
    titleEnterEmail: 'Enter your email',
    labelEmail: 'Email',
    emailValidationError: 'Your email is incorrect. Please try again.',
    titleEnterPasswordOrLoginWithSso: 'Enter password or login with SSO',
    titleEnterPassword: 'Enter your password',
    labelPassword: 'Password',
    titleSelectPayerEntities: 'Select the payer and payer entities',
    titleSelectPayer: 'Select payer',
    titleConsentScopes: 'Grant access',
    googleButtonText: 'Sign in with Google',
    ssoButtonText: 'Continue with single sign-on (SSO)',
    buttonTextNext: 'Next',
    buttonTextConfirm: 'Confirm',
    buttonTextCancel: 'Cancel',
    passwordLoginError: 'Your password is incorrect',
    titlePermissionName: 'Name',
    titlePermissionDescription: 'Description',
    titlePayers: 'Payers',
    labelPayerConsent: 'Payer',
    labelPermissionsConsent: 'Permissions',
    labelPermissionsConsentText: 'requires the following permissions to access and export information from the Tipalti Service',
    dividerOr: 'Or',
    payerSubscribed: 'Already set',
    consentAllow: 'Allow',
    consentAllowAgreementText:
        'By selecting "Allow", you confirm that you are permitting [third party] to access and export information from the Tipalti Service, that once exported the information will no longer be controlled by Tipalti, and that the information exported will be processed in accordance with [third party]\'s policies and agreements.',
    tipaltiLogo: 'Tipalti logo',
    contact: 'Contact',
    privacy: 'Privacy',
    support: 'Support',
    backText: 'Back'
};

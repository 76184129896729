export default {
    apiUrls: {
        getSetupInfo: '/api/v1/TwoFactorAuthentication/GetAuthenticatorSetupInfo',
        validateTwoFactorCode: '/api/v1/TwoFactorAuthentication/ValidateTwoFactorCode'
    },
    firstLoginTitle: '2-step verification',
    title: 'Authentication',
    titleTooltip: '2-step verification makes your account more secure by verifying your identity from two distinct sources.',
    verifyButton: 'Verify',
    verifyButtonLoading: 'Verifying...',
    setupInstruction: 'To enable authentication, use the Google Authenticator app to:',
    pinCodeTextFirstLogin:
        'When you’re done, the Google authenticator app will provide you with a unique verification code which you should enter into the box below',
    pinCodeText: 'Enter the Google Authenticator code into the box below',
    invalidPinCodeText: 'The code is incorrect or has expired',
    pinCodePlaceholder: 'Enter code here',
    scanQRCode: 'Scan this QR code',
    manualCode: 'Enter this keycode',
    or: 'Or',
    getAuthenticatorSetupInfoError: 'Failed to get two factor authentication settings, please try again.',
    validatePinCodeError: 'Failed to validate pin code, please try again.',
    minPinCodeLength: 6,
    pinCodeValidationStatus: {
        success: 1,
        invalidPinCode: 2,
        userIsLocked: 3,
        generalError: 4
    }
};

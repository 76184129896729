import Vue from 'vue';
import Notifications from 'vue-notification';
import fontAwesomeSetup from '@/lib/fontAwesomeSetup';
import vTooltip from 'v-tooltip';

Vue.use(vTooltip);
// to use icons they must be registered
fontAwesomeSetup(Vue);

Vue.use(Notifications);

Vue.component('TipSpinner', () =>
    import(
        /* webpackChunkName: "TipSpinner" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        '@tipalti/vue-controls/dist/esm/TipSpinner'
    )
);
Vue.component('TipTextInput', () => {
    import(/* webpackChunkName: "TipButton" */ '@/style/_tip-text-input.scss');
    return import(/* webpackChunkName: "tipTextInput" */ /* webpackPreload: true */ '@tipalti/vue-controls/dist/esm/TipTextInput');
});

Vue.component('TipButton', () => {
    import(/* webpackChunkName: "TipButton" */ '@/style/_tip-button.scss');
    return import(
        /* webpackChunkName: "TipButton" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        '@tipalti/vue-controls/dist/esm/TipButton'
    );
});

Vue.component('TipButtonBorder', () => {
    import(/* webpackChunkName: "TipButtonBorder" */ '@/style/_tip-button-border.scss');
    return import(
        /* webpackChunkName: "TipButtonBorder" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        '@tipalti/vue-controls/dist/esm/TipButton'
    );
});

Vue.component('TipMultiSelect', () =>
    import(
        /* webpackChunkName: "TipMultiSelect" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        '@tipalti/vue-controls/dist/esm/TipMultiSelect'
    )
);

Vue.component('TipMultiChecker', () =>
    import(
        /* webpackChunkName: "TipMultiChecker" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        '@tipalti/vue-controls/dist/esm/TipMultiChecker'
    )
);

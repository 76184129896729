// font awesome svg icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faSpinner, faCheck, faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

export default function fontAwesomeSetup(Vue) {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    // to use icons they must be registered
    // regular
    library.add(faBan);
    library.add(faSpinner);
    library.add(faCheck);
    library.add(faAngleLeft);
    // duotone
    // light
    library.add(faCheckCircle);
    library.add(faInfoCircle);

    return FontAwesomeIcon;
}

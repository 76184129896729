import Vue from 'vue';
import VueRouter from 'vue-router';
import loginConsts from '@/consts/loginConsts';
import store from '@/store';

Vue.use(VueRouter);

const AppSubscriberBase = () => import('@/components/AppSubscriber/AppSubscriberBase');
const InternalLoginBase = () => import('@/components/InternalLogin/InternalLoginBase');
const InitializeLogin = () => import('@/components/Login/InitializeLogin');
const AppSubscriberLoginUserFlowEmail = () => import('@/components/AppSubscriber/Login/AppSubscriberLoginUserFlowEmail');
const AppSubscriberLoginAuthenticate = () => import('@/components/AppSubscriber/Login/AppSubscriberLoginAuthenticate');
const LoginAuthenticate = () => import('@/components/Login/LoginAuthenticate');
const SelectPayerEntities = () => import('@/components/PayerEntities/SelectPayerEntities');
const ConsentScopes = () => import('@/components/Consent/ConsentScopes');
const AppSubscriberMissingPermission = () => import('@/components/AppSubscriber/MissingPermission/AppSubscriberMissingPermission');
const TwoFactorAuthentication = () => import('@/components/TwoFactorAuthentication/TwoFactorAuthentication');

const routes = [
    {
        path: '/',
        redirect: loginConsts.urls.baseLoginAppUrl
    },
    {
        path: loginConsts.urls.baseLoginAppUrl,
        component: InitializeLogin
    },
    {
        path: loginConsts.urls.baseSubscriberAppUrl,
        component: AppSubscriberBase,
        children: [
            {
                path: loginConsts.urls.urlPathLoginUserFlowEmail,
                component: AppSubscriberLoginUserFlowEmail
            },
            {
                path: loginConsts.urls.urlPathLoginAuthenticate,
                component: AppSubscriberLoginAuthenticate
            },
            {
                path: loginConsts.urls.urlPathLoginSelectPayerEntities,
                component: SelectPayerEntities
            },
            {
                path: loginConsts.urls.urlPathLoginConsentScopes,
                component: ConsentScopes
            },
            {
                path: loginConsts.urls.urlPathMissingPermission,
                component: AppSubscriberMissingPermission
            }
        ]
    },
    {
        path: loginConsts.urls.baseInternalLoginAppUrl,
        component: InternalLoginBase,
        children: [
            {
                path: loginConsts.urls.urlPathLoginAuthenticate,
                component: LoginAuthenticate
            },
            {
                path: loginConsts.urls.urlPathLoginTwoFactor,
                component: TwoFactorAuthentication
            }
        ]
    },
    {
        path: `${loginConsts.urls.baseLoginAppUrl}${loginConsts.urls.urlPathFailedLoginAttempt}`,
        component: InitializeLogin,
        props: { didLoginAttemptFail: true }
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach(async (to, from, next) => {
    await store.dispatch('login/initializeErrorDetailsLogin');
    await store.dispatch('consent/initializeErrorDetailsConsent');

    next();
});

export default router;

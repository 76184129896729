export default {
    urls: {
        baseLoginAppUrl: '/login-app',
        baseInternalLoginAppUrl: '/login-app/internal',
        baseSubscriberAppUrl: '/login-app/subscriber',
        urlPathLoginAuthenticate: 'login-authenticate',
        urlPathLoginUserFlowEmail: 'login-user-flow-email',
        urlPathLoginSelectPayerEntities: 'select-payer-entities',
        urlPathLoginConsentScopes: 'consent-scopes',
        urlPathLoginTwoFactor: 'two-factor',
        urlPathFailedLoginAttempt: '/failed-login-attempt',
        urlPathMissingPermission: 'missing-permission'
    }
};

import TipaltiLogger from '@tipalti/logger';
import compact from 'lodash/compact';
import some from 'lodash/some';
import Vue from 'vue';

/* istanbul ignore next */
function logger() {
    const { SENTRY_DSN, RELEASE_ID, LOGGING_ENV, LOGGING_IGNORE_HOSTS, LOGGING_ENABLED } = process.env;

    function getIgnoredHosts() {
        // return only valid values
        const ignoredHosts = LOGGING_IGNORE_HOSTS?.split(',') ?? [];
        return compact(['m.docker.payrad.com', 'localhost', ...ignoredHosts]);
    }

    function isLoggingEnabled() {
        // Ignore Auto-Environments // TIP-35203
        /* istanbul ignore next */
        const ignoredHosts = getIgnoredHosts();
        if (some(ignoredHosts, (ignored) => window.location.host.includes(ignored))) {
            return false;
        }
        return LOGGING_ENABLED;
    }

    let logger;
    try {
        const options = {
            Vue
        };
        options.loggingEnabled = isLoggingEnabled();
        options.release = RELEASE_ID;
        options.environment = LOGGING_ENV || window.location.host;
        options.DSN = SENTRY_DSN;
        logger = new TipaltiLogger(options);
    } catch (ex) {
        if (process.env.NODE_ENV === 'production') {
            throw ex;
        }
    }

    function addContext(context = {}) {
        logger?.addContext(context);
    }

    function error(exception) {
        logger?.error(exception);
    }

    function warn(message) {
        logger?.warn(message);
    }

    function info(message) {
        logger?.info(message);
    }

    function debug(message) {
        logger?.debug(message);
    }

    return {
        addContext,
        error,
        warn,
        info,
        debug
    };
}

export default logger();

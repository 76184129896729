import api from '@/api';
import Vue from 'vue';
import isNil from 'lodash/isNil';

export const SET_LOADING = 'SET_LOADING';
export const SET_OIDC_RETURN_URL = 'SET_OIDC_RETURN_URL';
export const SET_LOGIN_PROCESS_INITIAZLIZED = 'SET_LOGIN_PROCESS_INITIAZLIZED';
export const SET_SSO_LOGIN_URL = 'SET_SSO_LOGIN_URL';
export const SET_IS_APP_LOGIN_FLOW_SSO_ONLY = 'SET_IS_APP_LOGIN_FLOW_SSO_ONLY';
export const SET_IDENTITY_PROVIDER_TYPE = 'SET_IDENTITY_PROVIDER_TYPE';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_IS_USER_LOGIN_FLOW_SSO = 'SET_IS_USER_LOGIN_FLOW_SSO';
export const SET_IS_USER_LOGIN_FLOW_PASSWORD = 'SET_IS_USER_LOGIN_FLOW_PASSWORD';
export const SET_IS_USER_PASSWORD_VALID = 'SET_IS_USER_PASSWORD_VALID';
export const SET_IS_USER_EMAIL_VALID = 'SET_IS_USER_EMAIL_VALID';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const SET_USER_PAYERS = 'SET_USER_PAYERS';
export const SET_CAN_SUBSCRIBE = 'SET_CAN_SUBSCRIBE';
export const SET_APPLICATION_NAME = 'SET_APPLICATION_NAME';
export const SET_SHOW_ERROR_LOGIN = 'SET_SHOW_ERROR_LOGIN';
export const SET_LOGIN_ERROR_MESSAGE = 'SET_LOGIN_ERROR_MESSAGE';

const state = {
    isLoading: false,

    loginProcessInitialized: false,

    oidcReturnUrl: null,

    isAppLoginFlowSsoOnly: false,

    isUserLoginFlowSso: false,
    isUserLoginFlowPassword: false,

    ssoLoginUrl: null,
    identityProviderType: null,

    userEmail: null,
    isUserPasswordValid: false,
    isUserEmailValid: false,

    redirectUrl: null,

    userPayers: [],
    isSubscribeAllowed: false,
    applicationName: null,

    showErrorLogin: false,
    loginErrorMessage: ''
};

function setErrorDetails(commit, response) {
    commit(SET_SHOW_ERROR_LOGIN, response.showError);
    commit(SET_LOGIN_ERROR_MESSAGE, response.errorMessage);
}

const actions = {
    initializeLoginProcess: async ({ commit }, request) => {
        commit(SET_LOADING, true);

        const initializeLoginProcessResponse = await api.initializeLoginProcess(request);

        commit(SET_OIDC_RETURN_URL, request.oidcReturnUrl);
        commit(SET_LOGIN_PROCESS_INITIAZLIZED, initializeLoginProcessResponse);
        commit(SET_SSO_LOGIN_URL, initializeLoginProcessResponse.ssoRedirectUrl);
        commit(SET_IS_APP_LOGIN_FLOW_SSO_ONLY, initializeLoginProcessResponse.isLoginFlowSsoOnly);
        commit(SET_IDENTITY_PROVIDER_TYPE, initializeLoginProcessResponse.identityProviderType);

        commit(SET_LOADING, false);
    },

    initializeLoginByUserFlow: async ({ commit }, request) => {
        commit(SET_LOADING, true);

        const initializeLoginByUserFlowRequest = {
            userEmail: request.userEmail,
            oidcReturnUrl: request.oidcReturnUrl
        };

        commit(SET_USER_EMAIL, initializeLoginByUserFlowRequest.userEmail);

        const initializeLoginByUserFlowResponse = await api.initializeLoginByUserFlow(initializeLoginByUserFlowRequest);

        commit(SET_IS_USER_EMAIL_VALID, initializeLoginByUserFlowResponse.isValid);
        commit(SET_IS_USER_LOGIN_FLOW_SSO, initializeLoginByUserFlowResponse.isLoginFlowSso);
        commit(SET_IS_USER_LOGIN_FLOW_PASSWORD, initializeLoginByUserFlowResponse.isLoginFlowPassword);
        commit(SET_IDENTITY_PROVIDER_TYPE, initializeLoginByUserFlowResponse.identityProviderType);
        commit(SET_SSO_LOGIN_URL, initializeLoginByUserFlowResponse.ssoRedirectUrl);

        commit(SET_LOADING, false);
    },

    authenticateUserPassword: async ({ commit }, request) => {
        commit(SET_LOADING, true);

        const authenciateUserPasswordRequest = {
            userEmail: request.userEmail,
            password: request.password,
            oidcReturnUrl: request.oidcReturnUrl
        };

        const authenticateUserPasswordResponse = await api.authenticateUserPassword(authenciateUserPasswordRequest);

        setErrorDetails(commit, authenticateUserPasswordResponse);

        commit(SET_IS_USER_PASSWORD_VALID, authenticateUserPasswordResponse.isValid);
        commit(SET_REDIRECT_URL, authenticateUserPasswordResponse.redirectUrl);

        commit(SET_LOADING, false);
    },
    canSubscribe: async ({ commit }) => {
        commit(SET_LOADING, true);

        const canSubscribeResponse = await api.canSubscribe();

        setErrorDetails(commit, canSubscribeResponse);

        commit(SET_CAN_SUBSCRIBE, canSubscribeResponse.canSubscribe);
        commit(SET_APPLICATION_NAME, canSubscribeResponse.applicationName);

        commit(SET_LOADING, false);
    },
    getPayersForCurrentLoginStateUser: async ({ commit }) => {
        commit(SET_LOADING, true);

        const getPayersForCurrentLoginStateUserResponse = await api.getPayersForCurrentLoginStateUser();

        setErrorDetails(commit, getPayersForCurrentLoginStateUserResponse);

        commit(SET_OIDC_RETURN_URL, getPayersForCurrentLoginStateUserResponse.oidcReturnUrl);
        commit(SET_USER_PAYERS, getPayersForCurrentLoginStateUserResponse.payers);

        commit(SET_LOADING, false);
    },
    createAndSigninApplicativeUser: async ({ commit }, request) => {
        commit(SET_LOADING, true);

        const createAndSigninApplicativeUserRequest = {
            selectedPayerId: request.selectedPayerId,
            selectedPayerEntityIdArray: request.selectedPayerEntityIdArray
        };

        const createAndSigninApplicativeUserResponse = await api.createAndSigninApplicativeUser(createAndSigninApplicativeUserRequest);

        setErrorDetails(commit, createAndSigninApplicativeUserResponse);

        commit(SET_REDIRECT_URL, createAndSigninApplicativeUserResponse.redirectUrl);

        commit(SET_LOADING, false);
    },
    initializeErrorDetailsLogin: async ({ commit }, request) => {
        setErrorDetails(commit, { showError: false, errorMessage: '' });
    },
    setErrorLogin: async ({ commit }, errorMessage) => {
        setErrorDetails(commit, { showError: true, errorMessage });
    }
};

const mutations = {
    [SET_LOADING](state, isLoading) {
        Vue.set(state, 'isLoading', isLoading);
    },
    [SET_OIDC_RETURN_URL](state, oidcReturnUrl) {
        Vue.set(state, 'oidcReturnUrl', oidcReturnUrl);
    },
    [SET_LOGIN_PROCESS_INITIAZLIZED](state, initializeLoginProcessResponse) {
        Vue.set(state, 'loginProcessInitialized', isNil(initializeLoginProcessResponse) ? false : true);
    },
    [SET_SSO_LOGIN_URL](state, ssoLoginUrl) {
        Vue.set(state, 'ssoLoginUrl', ssoLoginUrl);
    },
    [SET_IS_APP_LOGIN_FLOW_SSO_ONLY](state, isLoginFlowSsoOnly) {
        Vue.set(state, 'isAppLoginFlowSsoOnly', isLoginFlowSsoOnly);
    },
    [SET_IDENTITY_PROVIDER_TYPE](state, identityProviderType) {
        Vue.set(state, 'identityProviderType', identityProviderType);
    },
    [SET_IS_USER_LOGIN_FLOW_PASSWORD](state, isLoginFlowPassword) {
        Vue.set(state, 'isUserLoginFlowPassword', isLoginFlowPassword);
    },
    [SET_IS_USER_LOGIN_FLOW_SSO](state, isLoginFlowSso) {
        Vue.set(state, 'isUserLoginFlowSso', isLoginFlowSso);
    },
    [SET_USER_EMAIL](state, userEmail) {
        Vue.set(state, 'userEmail', userEmail);
    },
    [SET_IS_USER_PASSWORD_VALID](state, isUserPasswordValid) {
        Vue.set(state, 'isUserPasswordValid', isUserPasswordValid);
    },
    [SET_IS_USER_EMAIL_VALID](state, isUserEmailValid) {
        Vue.set(state, 'isUserEmailValid', isUserEmailValid);
    },
    [SET_REDIRECT_URL](state, redirectUrl) {
        Vue.set(state, 'redirectUrl', redirectUrl);
    },
    [SET_USER_PAYERS](state, userPayers) {
        Vue.set(state, 'userPayers', userPayers);
    },
    [SET_CAN_SUBSCRIBE](state, isSubscribeAllowed) {
        Vue.set(state, 'isSubscribeAllowed', isSubscribeAllowed);
    },
    [SET_APPLICATION_NAME](state, applicationName) {
        Vue.set(state, 'applicationName', applicationName);
    },
    [SET_SHOW_ERROR_LOGIN](state, showErrorLogin) {
        Vue.set(state, 'showErrorLogin', showErrorLogin);
    },
    [SET_LOGIN_ERROR_MESSAGE](state, loginErrorMessage) {
        Vue.set(state, 'loginErrorMessage', loginErrorMessage);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};

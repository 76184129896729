import api from '@/api';
import Vue from 'vue';
import { SET_OIDC_RETURN_URL } from './login';

export const SET_CLIENT_SCOPES = 'SET_CLIENT_SCOPES';

export const SET_LOADING = 'SET_LOADING';
export const SET_PAYER_NAME = 'SET_PAYER_NAME';
export const SET_APPLICATION_NAME = 'SET_APPLICATION_NAME';

export const SET_SHOW_ERROR_CONSENT = 'SET_SHOW_ERROR_CONSENT';
export const SET_CONSENT_ERROR_MESSAGE = 'SET_CONSENT_ERROR_MESSAGE';

const state = {
    isLoading: false,

    oidcReturnUrl: null,
    clientScopes: null,
    payerName: null,
    applicationName: null,

    showErrorConsent: false,
    consentErrorMessage: ''
};

function setErrorDetails(commit, response) {
    commit(SET_SHOW_ERROR_CONSENT, response.showError);
    commit(SET_CONSENT_ERROR_MESSAGE, response.errorMessage);
}

const actions = {
    getScopesByLoginState: async ({ commit }, request) => {
        commit(SET_LOADING, true);

        const getClientScopesByLoginStateResponse = await api.getScopesByLoginState(request);

        setErrorDetails(commit, getClientScopesByLoginStateResponse);

        commit(SET_OIDC_RETURN_URL, getClientScopesByLoginStateResponse.oidcReturnUrl);
        commit(SET_CLIENT_SCOPES, getClientScopesByLoginStateResponse.scopes);
        commit(SET_PAYER_NAME, getClientScopesByLoginStateResponse.payerName);
        commit(SET_APPLICATION_NAME, getClientScopesByLoginStateResponse.clientName);

        commit(SET_LOADING, false);
    },
    grantConsent: async ({ commit }, request) => {
        commit(SET_LOADING, true);

        const response = await api.grantConsent(request);

        setErrorDetails(commit, response);

        if (!response.showError) {
            commit(SET_LOADING, false);
            return response.redirectUrl;
        }
    },
    initializeErrorDetailsConsent: async ({ commit }, request) => {
        setErrorDetails(commit, { showError: false, errorMessage: '' });
    }
};

const mutations = {
    [SET_LOADING](state, isLoading) {
        Vue.set(state, 'isLoading', isLoading);
    },
    [SET_CLIENT_SCOPES](state, scopes) {
        Vue.set(state, 'clientScopes', scopes);
    },
    [SET_OIDC_RETURN_URL](state, oidcReturnUrl) {
        Vue.set(state, 'oidcReturnUrl', oidcReturnUrl);
    },
    [SET_PAYER_NAME](state, payerName) {
        Vue.set(state, 'payerName', payerName);
    },
    [SET_APPLICATION_NAME](state, applicationName) {
        Vue.set(state, 'applicationName', applicationName);
    },
    [SET_SHOW_ERROR_CONSENT](state, showErrorConsent) {
        Vue.set(state, 'showErrorConsent', showErrorConsent);
    },
    [SET_CONSENT_ERROR_MESSAGE](state, consentErrorMessage) {
        Vue.set(state, 'consentErrorMessage', consentErrorMessage);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};

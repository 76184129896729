export default {
    initializeLoginProcess: '/api/v1/Authorization/InitializeLoginProcess',
    initializeLoginByUserFlow: '/api/v1/Authorization/InitializeLoginByUserFlow',
    authenticateUserPassword: '/api/v1/Authorization/AuthenticateUserPassword',
    canSubscribe: '/api/v1/ThirdPartyApp/CanSubscribe',
    getPayersForCurrentLoginStateUser: '/api/v1/PayerManagement/GetPayersForCurrentLoginStateUser',
    createAndSigninApplicativeUser: '/api/v1/PayerManagement/CreateAndSigninApplicativeUser',
    getScopesByLoginState: '/api/v1/LoginClientScope/GetScopesByLoginState',
    grantConsent: '/api/v1/Consent/GrantConsent'
};

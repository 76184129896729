import LogRocket from 'logrocket';

const PROJECT_IDS = {
    PRODUCTION: 'kqqd4c/idp-login-prod',
    DEV: 'kqqd4c/idp-login-dev'
};

function resolveProjectConfig() {
    const { host } = window.location;
    let projectId;
    if (host.endsWith('.sandbox.tipalti.com')) {
        projectId = PROJECT_IDS.DEV;
    } else if (host.endsWith('.tipalti.com')) {
        projectId = PROJECT_IDS.PRODUCTION;
    } else {
        projectId = PROJECT_IDS.DEV;
    }

    return projectId;
}

function init() {
    LogRocket.init(resolveProjectConfig(), {
        network: {
            requestSanitizer: (request) => {
                const body = JSON.parse(request.body);

                if (body.password) {
                    body.password = '******';
                    request.body = JSON.stringify(body);
                }

                return request;
            }
        }
    });
}

function addContext(email) {
    LogRocket.identify(email);
}

function error(exception) {
    LogRocket.captureException(exception);
}

const logrocket = {
    init,
    addContext,
    error
};

export default logrocket;

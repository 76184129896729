<template>
    <div id="login-app">
        <notifications position="top center" classes="login-app__login-notification" />

        <router-view />
    </div>
</template>

<script>
export default {
    name: 'LoginApp'
};
</script>
<style lang="scss">
#login-app {
    @import 'modern-css-reset';
    @import url('https://fonts.googleapis.com/css2?family=Lato&display=block');

    font-family: $font-family;
}
</style>

import axios from 'axios';
import identity from 'lodash/identity';

async function postAsync(url, params, cache) {
    return axios.post(url, params, {
        cache: cache || false,
        dataFetch: true,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
    });
}

async function postFileAsync(url, params) {
    return axios.post(url, params, {
        transformRequest: identity,
        dataFetch: true,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': undefined
        }
    });
}

async function getAsync(url, params, cache) {
    if (params === undefined || params === null) {
        params = { ts: new Date().getTime() };
    }

    return axios.get(url, {
        cache: cache || false,
        params,
        dataFetch: true,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
    });
}

async function downloadFileAsync(url, params, cache) {
    if (params === undefined || params === null) {
        params = { ts: new Date().getTime() };
    }

    return axios.get(url, {
        responseType: 'arraybuffer',
        cache: cache || false,
        params,
        dataFetch: true,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
    });
}

async function downloadFilePostAsync(url, params, cache) {
    return axios.post(url, params, {
        responseType: 'arraybuffer',
        cache: cache || false,
        dataFetch: true,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
    });
}

const httpService = {
    postAsync,
    postFileAsync,
    getAsync,
    downloadFileAsync,
    downloadFilePostAsync
};

export default httpService;
